import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  selected_lng: string = "EN"
  constructor(
    private translate: TranslateService
  ) {
    // let lng = localStorage.getItem('lng');
    // if (lng == null || lng == '' || lng == undefined) lng = 'en';
    // translate.setDefaultLang(lng);
    // if (lng == 'en') lng = 'EN';
    // if (lng == 'ja') lng = '日本語';
    // this.selected_lng = lng.toUpperCase();
  }

  lngChangeHandler(lng: any) {
    localStorage.setItem('lng', lng);
    location.reload();
  }

  getPdfLink(type: string, defaultLanguage: string = 'en'): string {
    const currentLanguage = localStorage.getItem('lng') || defaultLanguage;
    let pdfLink = '';

    if (currentLanguage === 'en' || !currentLanguage) {
        if (type === 'customer') {
            pdfLink = '/assets/pdf/customer_brochure.pdf';
        } else if (type === 'partner') {
            pdfLink = '/assets/pdf/English_Brouchure_agency_new may_23.pdf';
        } else if (type === 'manufacturing') {
            pdfLink = '/assets/pdf/Brochure _Manufacturing Aug 2023 (1).pdf';
        } else if (type === 'automobile') {
            pdfLink = '/assets/pdf/Brochure _Automobile Aug 2023 (2).pdf';
        } else if (type === 'construction') {
            pdfLink = '/assets/pdf/Brochure _Construction Aug 2023 (3).pdf';
        } else if (type === 'careworker') {
            pdfLink = '/assets/pdf/Brochure _Careworker Aug 2023 (3).pdf';
        } else if (type === 'hospitality') {
            pdfLink = '/assets/pdf/Brochure _Hospitality Aug 2023 (2).pdf';
        } else if (type === 'agriculture') {
            pdfLink = '/assets/pdf/Brochure _Agriculture Aug 2023 (5).pdf';
        }
    }
    
    else if (currentLanguage === 'ja') {
        if (type === 'customer') {
            pdfLink = '/assets/pdf/customer_brochure.pdf';
        } else if (type === 'partner') {
            pdfLink = '/assets/pdf/English_Brouchure_agency_new may_23.pdf';
          } else if (type === 'manufacturing') {
            pdfLink = '/assets/pdf/Brochure _Manufacturing Aug 2023 (1).pdf';
        } else if (type === 'automobile') {
            pdfLink = '/assets/pdf/Brochure _Automobile Aug 2023 (2).pdf';
        } else if (type === 'construction') {
            pdfLink = '/assets/pdf/Brochure _Construction Aug 2023 (3).pdf';
        } else if (type === 'careworker') {
            pdfLink = '/assets/pdf/Brochure _Careworker Aug 2023 (3).pdf';
        } else if (type === 'hospitality') {
            pdfLink = '/assets/pdf/Brochure _Hospitality Aug 2023 (2).pdf';
        } else if (type === 'agriculture') {
            pdfLink = '/assets/pdf/Brochure _Agriculture Aug 2023 (5).pdf';
        }
    }

    return pdfLink;
}


  ngOnInit(): void {
    let lng = localStorage.getItem('lng');
    if (lng == null || lng == '' || lng == undefined) lng = 'en';
    this.translate.setDefaultLang(lng);
    if (lng == 'en') lng = 'EN';
    if (lng == 'ja') lng = '日本語';
    this.selected_lng = lng.toUpperCase();
  }



}

