<header class="main-header fixed-top">
    <div class="header-lower">
        <div class="outer-box clearfix">
            <div class="menu-area pull-left clearfix">
                <figure class="logo-box"><a routerLink="/"><img src="assets/images/logo.png" alt=""></a></figure>
                <div class="mobile-nav-toggler">
                    <i class="icon-bar"></i>
                    <i class="icon-bar"></i>
                    <i class="icon-bar"></i>
                </div>
                <nav class="main-menu navbar-expand-md navbar-light">
                    <div class="collapse navbar-collapse show clearfix" id="navbarSupportedContent">
                        <ul class="navigation clearfix">
                            <li class="dropdown"><a routerLink="/japanese-training">{{ 'header-one.learning' | translate
                                    }}</a><!--routerLink="/japanese-training"-->
                                <!-- <ul>
                                    <li><a routerLink="/japanese-training">{{ 'header-one.Japanese_Training' | translate
                                            }}</a></li>
                                    <li><a routerLink="/technical_training">{{ 'header-one.Technical Training' |
                                            translate }}</a></li>
                                </ul> -->
                            </li>
                            <li class="dropdown"><a routerLink="/jobs_in_japan_semi_skilled">{{ 'header-one.For Candidates' | translate
                                    }}</a><!--routerLink="/jobs_in_japan"-->
                                <!-- <ul>
                                    <li><a routerLink="/jobs_in_japan_semi_skilled">{{ 'header-one.Semi-Skilled &
                                            Technical Interns' | translate }}</a></li>
                                    <li><a routerLink="/it_engineers">{{ 'header-one.IT Engineers' | translate }}</a>
                                    </li>
                                </ul> -->
                            </li>
                            <li class="dropdown"><a routerLink="/resourcing_semi_skilled">{{ 'header-one.For Corporates Recruiters' | translate
                                    }}</a><!-- routerLink="/resourcing" -->
                                <!-- <ul>
                                    <li><a routerLink="/resourcing_semi_skilled">{{ 'header-one.Semi-Skilled & Technical
                                            Interns' | translate }}</a></li>
                                    <li><a routerLink="/resourcing_professional">{{ 'header-one.IT Engineers' |
                                            translate }}</a></li>
                                </ul> -->
                            </li>
                            <li class="dropdown"><a href="javascript:;">{{ 'header-one.Other_Service' | translate
                                    }}</a><!--routerLink="/technology"-->
                                <ul>
                                    <li><a routerLink="/tech_services">{{ 'header-one.Higher_Education' | translate
                                            }}</a></li>
                                    <li><a routerLink="/learnchain">{{ 'header-one.Higher_Education1' | translate
                                    }}</a></li>
                                </ul>
                            </li>
                            <li class="dropdown"><a href="javascript:;">{{ 'header-one.brouchers' | translate
                                    }}</a><!--routerLink="/brouchers"-->
                                <ul>
                                    <li><a target="_blank" [href]="getPdfLink('customer', 'en')">{{
                                            'header-one.customer' | translate }}</a></li>

                                    <div class="PartnerAgency"><h3>Partner/Agency</h3>

                                    
                                    <li><a target="_blank" [href]="getPdfLink('manufacturing', 'en')">{{ 'header-one.manufacturing'
                                            | translate }}</a></li>
                                    <li><a target="_blank" [href]="getPdfLink('automobile', 'en')">{{ 'header-one.automobile'
                                            | translate }}</a></li>
                                    <li><a target="_blank" [href]="getPdfLink('construction', 'en')">{{ 'header-one.construction'
                                            | translate }}</a></li>
                                    <li><a target="_blank" [href]="getPdfLink('careworker', 'en')">{{ 'header-one.careworker'
                                            | translate }}</a></li>
                                    <li><a target="_blank" [href]="getPdfLink('hospitality', 'en')">{{ 'header-one.hospitality'
                                            | translate }}</a></li>
                                    <li><a target="_blank" [href]="getPdfLink('agriculture', 'en')">{{ 'header-one.agriculture'
                                            | translate }}</a></li>   
                                            
                                  </div>
                                </ul>
                            </li>

                            <li class="dropdown"><a routerLink="/rect"> Rect
                                    </a><!--routerLink="/technology"-->
                              
                            </li>

                            <div class=" d-lg-flex align-items-center pt-3 nepltest">

                                <li class="btn_top d-none">
                                    <a routerLink="/schedule_session" class="theme-btn-2 mr-lg-3">{{ 'header-one.Join a
                                        Demo Class' | translate }}</a>
                                </li>

                                <li class="btn_top ">
                                    <a href="https://ncareers.org/" target="_blank"
                                        class="theme-btn-one1  btntop1">Ncareers.org</a>
                                </li>

                            </div>


                            <!-- <li class="btn_top ">
                                <a routerLink="/schedule_session" class="theme-btn-one">{{ 'header-one.Join a Demo Class' | translate }}</a>
                            </li> -->

                            <!-- <li class="btn_top "><a routerLink="/schedule_session" class="theme-btn-one">{{ 'header-one.Join a Demo Class' | translate }}</a></li> -->
                        </ul>
                    </div>
                </nav>
            </div>

            <ul class="menu-right-content  clearfix">
                <li>
                    <div class="language">
                        <div class="lang-btn">
                            <i class="icon flaticon-planet-earth"></i>
                            <span class="txt">{{selected_lng}}</span>
                            <span class="arrow fa fa-angle-down"></span>
                        </div>
                        <div class="lang-dropdown">
                            <ul>
                                <li (click)="lngChangeHandler('en')">
                                    <a class="lang-en lang-select" data-lang="en">
                                        <img src="assets/images/flag_usa.png"> {{ 'header-one.ENGLISH' | translate }}
                                    </a>
                                </li>
                                <li (click)="lngChangeHandler('ja')">
                                    <a class="lang-es lang-select" data-lang="ja">
                                        <img src="assets/images/flag_japan.png"> {{ 'header-one.JAPANESE' | translate }}
                                    </a>
                                </li>
                            </ul>
                            <!-- <ul>
                                  <li><a href="#googtrans(en|en)" class="lang-en lang-select" data-lang="en"><img src="assets/images/flag_usa.png"> ENGLISH</a></li>
                                  <li><a href="#googtrans(en|ja)" class="lang-es lang-select" data-lang="ja"><img src="assets/images/flag_japan.png"> JAPANESE</a></li>
                            </ul> -->
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</header>